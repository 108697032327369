import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
} from "react";
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import "firebase/compat/auth";
import SpotbargeTable from "../../common/SpotbargeTable";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "../../common/SpotbargeLayout";
import GeneralErrorPage from "../../GeneralErrorPage";
import { withErrorBoundary } from "react-error-boundary";
import { useTradingGet, useTradingPost } from "../../../request";
import { DateTime } from 'luxon';
import { UserContext } from "../../AuthGuard";

const YourBarges = () => {
  const [user] = useContext(UserContext);
  const { t } = useTranslation();
  const [barges, setBarges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const get = useTradingGet();
  const post = useTradingPost();

  useEffect(() => {
    const fetchBarges = async () => {
      try {
        const result = await get("your-barges");
        if (result && !result.error) {
          setBarges(result);
        } else {
          setError(result.error ? result.error : t("index_somethingWentWrong"));
        }
      } catch (err) {
        setError(t("index_somethingWentWrong"));
      } finally {
        setLoading(false);
      }
    };

    fetchBarges();
  }, []);

  const handleDeleteBarge = async (bargeId) => {
    try {
      await post("delete-barge", { bargeId });
      const updatedBarges = await get("your-barges");
      setBarges(updatedBarges);
    } catch (err) {
      setError(t("index_somethingWentWrong"));
    }
  };

  const columns = useMemo(() => {
    const baseColumns = [
      { id: "loadDate", label: t("trading_your_barges_load_eta") },
      { id: "name", label: t("trading_your_barges_barge_name"), filter: "checkboxes" },
      { id: "user", label: t("trading_your_barges_user"), filter: "checkboxes" },
      { id: "size", label: t("trading_your_barges_barge_size_dwt") },
      { id: "preCargo1", label: t("trading_your_barges_pre_cargo_1"), filter: "checkboxes" },
      { id: "preCargo2", label: t("trading_your_barges_pre_cargo_2"), filter: "checkboxes" },
      { id: "preCargo3", label: t("trading_your_barges_pre_cargo_3"), filter: "checkboxes" },
      { id: "area", label: t("trading_your_barges_available_for_areas") },
      { id: "status", label: t("trading_your_barges_status") },
      { id: "info", label: "" },
    ];

    if (user?.role === "admin") {
      baseColumns.push({ id: "delete", label: "" });
    }

    return baseColumns;
  }, [user?.role]);

  const columnRenderMap = {
    loadDate: (row) => DateTime.fromFormat(row.loadDate, "yyyy-MM-dd").toFormat("dd/MM/yyyy"),
    info: (row) => (
      <IconButton>
        <InfoIcon />
      </IconButton>
    ),
    delete: (row) => (
     (
        <IconButton onClick={() => handleDeleteBarge(row.id)}>
          <DeleteIcon />
        </IconButton>
      )
    ),
  };

  return (
    <SpotbargeLayout title={t("trading_your_barges")}>
      <Box>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {!loading && !error && (
          <SpotbargeTable
            data={barges}
            columns={columns}
            columnRenderMap={columnRenderMap}
          />
        )}
      </Box>
    </SpotbargeLayout>
  );
};

export default withErrorBoundary(YourBarges, {
  fallback: <GeneralErrorPage />,
});
