import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
} from "react";
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import "firebase/compat/auth";
import SpotbargeTable from "../../common/SpotbargeTable";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "../../common/SpotbargeLayout";
import GeneralErrorPage from "../../GeneralErrorPage";
import { withErrorBoundary } from "react-error-boundary";
import { useTradingGet, useTradingPost } from "../../../request";
import { DateTime } from "luxon";
import { UserContext } from "../../AuthGuard";

export const formatTerminal = (terminal, extraTerminal) => {
  return extraTerminal ? (
    <span style={{ whiteSpace: "pre-wrap" }}>
      {terminal}
      <br />
      <strong>(+ {extraTerminal})</strong>
    </span>
  ) : terminal;
};

const YourCargos = () => {
  const { t } = useTranslation();
  const [cargos, setCargos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const get = useTradingGet();
  const post = useTradingPost();
  const [user] = useContext(UserContext);

  useEffect(() => {
    const fetchCargos = async () => {
      try {
        const result = await get("your-cargos");
        if (result && !result.error) {
          const formattedCargos = result.map((cargo) => ({
            ...cargo,
            loadWindow: `${DateTime.fromFormat(cargo.loadStart, "yyyy-MM-dd").toFormat("dd/MM/yyyy")} - ${DateTime.fromFormat(cargo.loadEnd, "yyyy-MM-dd").toFormat("dd/MM/yyyy")}`,
            loadTerminal: formatTerminal(cargo.loadTerminal, cargo.extraLoadTerminal),
            dischargeTerminal: formatTerminal(cargo.dischargeTerminal, cargo.extraDischargeTerminal),
          }));
          setCargos(formattedCargos);
        } else {
          setError(result.error ? result.error : t("index_somethingWentWrong"));
        }
      } catch (err) {
        setError(t("index_somethingWentWrong"));
      } finally {
        setLoading(false);
      }
    };

    fetchCargos();
  }, []);

  const handleDeleteCargo = async (cargoId) => {
    try {
      await post("delete-cargo", { cargoId });
      const updatedCargos = await get("your-cargos");
      setCargos(updatedCargos);
    } catch (err) {
      setError(t("index_somethingWentWrong"));
    }
  };

  const columns = useMemo(() => {
    const baseColumns = [
      { id: "loadWindow", label: t("trading_your_cargos_load_window") },
      { id: "loadTerminal", label: t("trading_your_cargos_load_terminal"), filter: "checkboxes" },
      { id: "dischargeTerminal", label: t("trading_your_cargos_discharge_terminal"), filter: "checkboxes" },
      { id: "product", label: t("trading_your_cargos_product"), filter: "checkboxes" },
      { id: "volume", label: t("trading_your_cargos_volume") },
      { id: "type", label: t("trading_your_cargos_type"), filter: "checkboxes" },
      { id: "area", label: t("trading_your_cargos_area"), filter: "checkboxes" },
      { id: "status", label: t("trading_your_cargos_status"), filter: "checkboxes" },
      { id: "user", label: t("trading_your_cargos_user"), filter: "checkboxes" },
      { id: "info", label: "" },
    ];

    if (user.role === "admin") {
      baseColumns.push({ id: "delete", label: "" });
    }

    return baseColumns;
  }, [user?.role]);

  const columnRenderMap = {
    info: (row) => (
      <IconButton>
        <InfoIcon />
      </IconButton>
    ),
    delete: (row) => (
      (
        <IconButton onClick={() => handleDeleteCargo(row.id)}>
          <DeleteIcon />
        </IconButton>
      )
    ),
  };

  return (
    <SpotbargeLayout title={t("trading_your_cargos")}>
      <Box>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {!loading && !error && (
          <SpotbargeTable
            data={cargos}
            columns={columns}
            columnRenderMap={columnRenderMap}
          />
        )}
      </Box>
    </SpotbargeLayout>
  );
};

export default withErrorBoundary(YourCargos, {
  fallback: <GeneralErrorPage />,
});
