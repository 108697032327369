import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";

const BidDialog = ({ open, onClose, onSubmit }) => {
  const [details, setDetails] = useState("");
  const [dischargeAt, setDischargeAt] = useState("");
  const [pricePerTon, setPricePerTon] = useState("");

  const handleSubmit = () => {
    const bidDetails = { details, dischargeAt, pricePerTon };
    onSubmit(bidDetails);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>1. Details</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
          <TextField
            fullWidth
            label="Details"
            variant="outlined"
            value={details}
            onChange={(e) => setDetails(e.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel>Discharge at</InputLabel>
            <Select
              value={dischargeAt}
              label="Discharge at"
              onChange={(e) => setDischargeAt(e.target.value)}
            >
              <MenuItem value="location1">Location 1</MenuItem>
              <MenuItem value="location2">Location 2</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Price per ton"
            type="number"
            variant="outlined"
            value={pricePerTon}
            onChange={(e) => setPricePerTon(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Confirm bid
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BidDialog;
