import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  CircularProgress,
  Alert,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import { Autocomplete } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import InfoIcon from '@mui/icons-material/Info';
import SpotbargeLayout from "../../common/SpotbargeLayout";
import { useTradingGet } from "../../../request";
import OutlinedSection from "../../atoms/OutlinedSection";
import SpotbargeTable from "../../common/SpotbargeTable";
import GeneralErrorPage from "../../GeneralErrorPage";
import { withErrorBoundary } from "react-error-boundary";
import { formatTerminal } from "../oil/YourCargos";
import BidDialog from "../BidDialog";

const FindCargo = () => {
  const { t } = useTranslation();
  const [barges, setBarges] = useState([]);
  const [selectedBarge, setSelectedBarge] = useState(null);
  const [cargos, setCargos] = useState([]);
  const [filteredCargos, setFilteredCargos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [flexibilityDays, setFlexibilityDays] = useState("");
  const [flexibilityTons, setFlexibilityTons] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedCargo, setSelectedCargo] = useState(null);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [selectedCargoInfo, setSelectedCargoInfo] = useState(null);

  const get = useTradingGet();

  useEffect(() => {
    const fetchBarges = async () => {
      try {
        const result = await get("your-barges");
        if (result && !result.error) {
          setBarges(result.filter((barge) => barge.status.toLowerCase() === "pending"));
        } else {
          setError(result.error ? result.error : t("index_somethingWentWrong"));
        }
      } catch (err) {
        console.log(err);
        setError(t("index_somethingWentWrong"));
      } finally {
        setLoading(false);
      }
    };

    fetchBarges();
  }, []);

  const handleBargeChange = async (event, newValue) => {
    setSelectedBarge(newValue);
    setFlexibilityDays("0");
    if (newValue) {
      setTableLoading(true);
      try {
        const result = await get("find-cargo", { bargeId: newValue.id });
        if (result && !result.error) {
          const formattedCargos = result.map((cargo) => ({
            ...cargo,
            loadWindow: `${DateTime.fromFormat(cargo.loadStart, "yyyy-MM-dd").toFormat("dd/MM/yyyy")} - ${DateTime.fromFormat(cargo.loadEnd, "yyyy-MM-dd").toFormat("dd/MM/yyyy")}`,
            loadPort: formatTerminal(cargo.loadPort, cargo.extraLoadPort),
            dischargePort: formatTerminal(cargo.dischargePort, cargo.extraDischargePort),
            productType: cargo.productType.toUpperCase(),
          }));
          setCargos(formattedCargos);
          filterCargos(formattedCargos, newValue, flexibilityDays);
        } else {
          setError(result.error ? result.error : t("index_somethingWentWrong"));
        }
      } catch (err) {
        console.log(err);
        setError(t("index_somethingWentWrong"));
      } finally {
        setTableLoading(false);
      }
    }
  };

  const filterCargos = (cargos, barge, flexibilityDays) => {
    const filtered = cargos.filter((cargo) => {
      const loadEta = DateTime.fromFormat(barge.loadDate, "yyyy-MM-dd");
      const loadStart = DateTime.fromFormat(cargo.loadStart, "yyyy-MM-dd");
      const loadEnd = DateTime.fromFormat(cargo.loadEnd, "yyyy-MM-dd");
      const daysFlexibility = parseInt(flexibilityDays) || 0;

      return (
        (loadEta >= loadStart && loadEta <= loadEnd) ||
        (loadEta >= loadStart.minus({ days: daysFlexibility }) && loadEta <= loadStart)
      );
    });
    setFilteredCargos(filtered);
  };

  useEffect(() => {
    if (selectedBarge) {
      filterCargos(cargos, selectedBarge, flexibilityDays);
    }
  }, [flexibilityDays, cargos, selectedBarge]);
  
  const handleBidClick = (cargo) => {
    setSelectedCargo(cargo);
  };

  const handleBidClose = () => {
    setSelectedCargo(null);
  };

  const handleInfoClick = (cargo) => {
    setSelectedCargoInfo(cargo);
    setInfoDialogOpen(true);
  };

  const handleInfoClose = () => {
    setInfoDialogOpen(false);
    setSelectedCargoInfo(null);
  };

  const columns = useMemo(
    () => [
      { id: "info", label: "" },
      { id: "loadWindow", label: t("trading_your_cargos_load_window") },
      { id: "loadPort", label: t("trading_your_cargos_load_port") },
      { id: "dischargePort", label: t("trading_your_cargos_discharge_port") },
      { id: "product", label: t("trading_your_cargos_product") },
      { id: "volume", label: t("trading_your_cargos_volume") },
      { id: "area", label: t("trading_your_cargos_area") },
      { id: "company", label: t("trading_your_cargos_company") },
      { id: "productType", label: t("trading_your_cargos_product_type") },
      { id: "terms", label: t("trading_your_cargos_terms") },
      { id: "bid", label: "" },
    ],
    [t]
  );

  const columnRenderMap = {
    bid: (row) => (
      <Button 
        variant="contained" 
        color="success"
        onClick={() => handleBidClick(row)}
      >
        {t("Bid")}
      </Button>
    ),
    info: (row) => {
      const hasInfo = row.comments || (row.tanks && (row.tanks.includes('stripped') || row.tanks.includes('inert_till_8')));
      if (!hasInfo) return null;
      
      return (
        <IconButton onClick={() => handleInfoClick(row)}>
          <InfoIcon />
        </IconButton>
      );
    }
  };

  return (
    <SpotbargeLayout title={t("Filter your search")}>
      <Box sx={{ p: 1 }}>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {!loading && !error && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Box sx={{ flex: "1 1 100%" }}>
              <OutlinedSection title={t("Select from your barges")}>
                <Autocomplete
                  fullWidth
                  options={barges}
                  getOptionLabel={(barge) =>
                    `Date: ${barge.loadDate}, Barge: ${barge.name}, dwt: ${barge.size}, pre cargo: ${barge.preCargo1} / ${barge.preCargo2} / ${barge.preCargo3}, Area: ${barge.area}`
                  }
                  value={selectedBarge}
                  onChange={handleBargeChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Select a barge")}
                      variant="outlined"
                    />
                  )}
                  renderOption={(props, barge) => (
                    <li style={{ display: 'flex', flexWrap: 'wrap' }} {...props}>
                      <span style={{ fontWeight: 'bold' }}>Date:&nbsp;</span>{barge.loadDate},&nbsp;
                      <span style={{ fontWeight: 'bold' }}>Barge:&nbsp;</span>{barge.name},&nbsp;
                      <span style={{ fontWeight: 'bold' }}>dwt:&nbsp;</span>{barge.size},&nbsp;
                      <span style={{ fontWeight: 'bold' }}>pre cargo:&nbsp;</span>{barge.preCargo1} / {barge.preCargo2} / {barge.preCargo3},&nbsp;
                      <span style={{ fontWeight: 'bold' }}>Area:&nbsp;</span>{barge.area}
                    </li>
                  )}
                />
              </OutlinedSection>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 1,
              }}
            >
              <Box sx={{ flex: "1 1 50%" }}>
                <OutlinedSection title={t("Flexibility dates")}>
                  <RadioGroup
                    row
                    value={flexibilityDays}
                    onChange={(e) => setFlexibilityDays(e.target.value)}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="< 1 day" />
                    <FormControlLabel value="2" control={<Radio />} label="< 2 days" />
                    <FormControlLabel value="3" control={<Radio />} label="< 3 days" />
                  </RadioGroup>
                </OutlinedSection>
              </Box>
              <Box sx={{ flex: "1 1 50%" }}>
                <OutlinedSection title={t("Flexibility tons")}>
                  <RadioGroup
                    row
                    value={flexibilityTons}
                    onChange={(e) => setFlexibilityTons(e.target.value)}
                  >
                    <FormControlLabel value="500" control={<Radio />} label="< 500" />
                    <FormControlLabel value="1000" control={<Radio />} label="< 1000" />
                    <FormControlLabel value="3000" control={<Radio />} label="< 3000" />
                  </RadioGroup>
                </OutlinedSection>
              </Box>
            </Box>
            {selectedBarge && !error && (
              <Box sx={{ position: "relative" }}>
                {tableLoading && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      zIndex: 1,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <SpotbargeTable data={filteredCargos} columns={columns} columnRenderMap={columnRenderMap} />
              </Box>
            )}
          </Box>
        )}

        <BidDialog open={!!selectedCargo} onClose={handleBidClose} />

        <Dialog open={infoDialogOpen} onClose={handleInfoClose}>
          <DialogTitle>Additional Information</DialogTitle>
          <DialogContent>
            {selectedCargoInfo?.tanks && (
              selectedCargoInfo.tanks.includes('stripped') || selectedCargoInfo.tanks.includes('inert_till_8')
            ) && (
              <Typography paragraph>
                Tanks are {[
                  selectedCargoInfo.tanks.includes('stripped') && 'stripped',
                  selectedCargoInfo.tanks.includes('inert_till_8') && 'inert till 8'
                ].filter(Boolean).join(' and ')}.
              </Typography>
            )}
            {selectedCargoInfo?.comments && (
              <>
                <Typography sx={{ fontWeight: 'bold', mt: 2, mb: 1 }}>
                  Operator comments:
                </Typography>
              </>
            )}
            {selectedCargoInfo?.comments && (
              <Typography style={{ whiteSpace: 'pre-line' }}>
                {selectedCargoInfo.comments}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleInfoClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </SpotbargeLayout>
  );
};

export default withErrorBoundary(FindCargo, {
  fallback: <GeneralErrorPage />,
});
