import React from "react";
import { Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const offersData = [
  {
    title: "Cargo trip: SPOT / Gasoline / Amsterdam - Rotterdam / 3000t / 16.8.2024",
    details: [
      { date: "18.8.2024", operator: "Koeren", volume: "6500", barge: "Charissa", demurrage: "", shortNotice: "", minPay: "", price: "Waiting offer" },
      { date: "17.8.2024", operator: "DBS", volume: "8500", barge: "Datsutsu", demurrage: "300", shortNotice: "Yes", minPay: "5,20", price: "Bid" }
    ]
  },
  {
    title: "Cargo trip: Diesel, Koln - Basel, 2000t, 18.8.2024",
    details: [
      { date: "19.8.2024", operator: "DBS", volume: "9500", barge: "Maxcar", demurrage: "", shortNotice: "", minPay: "2000", price: "15,00 pt" }
    ]
  },
  {
    title: "Cargo trip: BOB, Amsterdam - Antwerp, 7000t, 20.8.2024",
    details: [
      { date: "20.8.2024", operator: "DBS", volume: "6500", barge: "Charissa", demurrage: "300", shortNotice: "Yes", minPay: "5000", price: "5,30" }
    ]
  }
];

const Offers = () => {
  const theme = useTheme();

  return (
    <Box sx={{ padding: 2 }}>
      {offersData.map((offer, index) => (
        <Box key={index} sx={{ marginBottom: 4 }}>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            {offer.title}
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
                  <TableCell>Details</TableCell>
                  <TableCell>Date laycan</TableCell>
                  <TableCell>Operator</TableCell>
                  <TableCell>Volume (m3)</TableCell>
                  <TableCell>Barge</TableCell>
                  <TableCell>Demurrage</TableCell>
                  <TableCell>Short notice</TableCell>
                  <TableCell>Min pay</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {offer.details.map((detail, detailIndex) => (
                  <TableRow key={detailIndex}>
                    <TableCell>{detail.date}</TableCell>
                    <TableCell>{detail.operator}</TableCell>
                    <TableCell>{detail.volume}</TableCell>
                    <TableCell>{detail.barge}</TableCell>
                    <TableCell>{detail.demurrage}</TableCell>
                    <TableCell>{detail.shortNotice}</TableCell>
                    <TableCell>{detail.minPay}</TableCell>
                    <TableCell>{detail.price}</TableCell>
                    <TableCell>
                      {detail.price === "Waiting offer" ? (
                        <Typography color="error">Waiting offer</Typography>
                      ) : (
                        <Button variant="contained" color="primary">
                          Bid
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ))}
    </Box>
  );
};

export default Offers;
