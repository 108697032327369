import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Alert,
  CircularProgress,
  MenuItem,
  Autocomplete,
  List,
  ListItem,
  ListItemText,
  Typography,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SpotbargeLayout from "../../common/SpotbargeLayout";
import GeneralErrorPage from "../../GeneralErrorPage";
import { withErrorBoundary } from "react-error-boundary";
import { useTradingPost, useTradingGet } from "../../../request";
import OutlinedSection from "../../atoms/OutlinedSection";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';

function EnterBarge() {
  const { t } = useTranslation();
  const [loadEta, setLoadEta] = useState(null);
  const [bargeName, setBargeName] = useState("");
  const [bargeSizeM3, setBargeSizeM3] = useState("");
  const [bargeSizeDwt, setBargeSizeDwt] = useState("");
  const [bargeEuropeNr, setBargeEuropeNr] = useState("");
  const [tankType, setTankType] = useState("");
  const [heating, setHeating] = useState("no");
  const [preCargo1, setPreCargo1] = useState("");
  const [preCargo2, setPreCargo2] = useState("");
  const [preCargo3, setPreCargo3] = useState("");
  const [areas, setAreas] = useState([]);
  const [tanks, setTanks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ text: "", severity: "" });
  const [ships, setShips] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [errors, setErrors] = useState([]);

  const post = useTradingPost();
  const get = useTradingGet();

  useEffect(() => {
    import("../data/ships.json").then((module) => setShips(module.default));
    import("../data/products.json").then((module) => setProductOptions(module.default));
  }, []);

  const handleClear = () => {
    setLoadEta(null);
    setBargeName("");
    setBargeSizeM3("");
    setBargeSizeDwt("");
    setBargeEuropeNr("");
    setTankType("");
    setHeating("no");
    setPreCargo1("");
    setPreCargo2("");
    setPreCargo3("");
    setAreas([]);
    setTanks([]);
    setErrors([]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setMessage({ text: "", severity: "" });
    setErrors([]);
    try {
      const res = await post("save-barge", {
        loadEta: loadEta?.toFormat('yyyy-MM-dd') || "",
        bargeName,
        bargeSizeM3,
        bargeSizeDwt,
        bargeEuropeNr,
        tankType,
        heating: heating === "yes",
        preCargo1: preCargo1?.label,
        preCargo2: preCargo2?.label,
        preCargo3: preCargo3?.label,
        areas,
        tanks,
      });
      if (!res) {
        setMessage({
          text: "Failed to save barge details. Please try again or contact us.",
          severity: "error",
        });
        return;
      } else if (res.error) {
        setMessage({ text: res.error, severity: "error" });
      } else if ("errors" in res) {
        setErrors(res.errors || []);
      } else {
        setMessage({ text: "Barge details saved successfully.", severity: "success" });
        handleClear();
      }
    } catch (err) {
      console.error(err);
      setMessage({
        text: "Failed to save barge details. Please try again or contact us.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const findTankType = (tankType) => {
    if(tankType.startsWith("coated")) return "coated";
    if(tankType.startsWith("mild")) return "mildsteel";
    if(tankType.startsWith("stainless")) return "stainless_steel";
    return "";
  }

  const handleBargeNameChange = async (e, newValue) => {
    setBargeName(newValue);
    if (newValue && ships.includes(newValue)) {
      try {
        const res = await get("barge-info", { name: newValue });
        if (res && !("error" in res)) {
          if (res.cubicM3) setBargeSizeM3(res.cubicM3);
          if (res.deadweight) setBargeSizeDwt(res.deadweight);
          if (res.europNr) setBargeEuropeNr(res.europNr);
          if (res.tankType) setTankType(findTankType(res.tankType.toLowerCase()));
          if (res.heating !== undefined) setHeating(res.heating === "Yes" ? "yes" : "no");
        }
      } catch (error) {
        console.error("Failed to fetch barge info:", error);
      }
    }
  };

  const handleAreaChange = (area) => (e) => {
    if (e.target.checked) {
      setAreas([...areas, area]);
    } else {
      setAreas(areas.filter((a) => a !== area));
    }
  };

  const handleTankChange = (tank) => (e) => {
    if (e.target.checked) {
      setTanks([...tanks, tank]);
    } else {
      setTanks(tanks.filter((t) => t !== tank));
    }
  };

  const renderErrors = () => {
    if (errors.length === 0) return null;
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        <Typography variant="h6">There were errors with your submission:</Typography>
        <List dense disablePadding sx={{ padding: 0, margin: 0 }}>
          {errors.map((error, index) => (
            <ListItem key={index} sx={{ padding: '2px 0' }}>
              <ListItemText primary={`- ${error.msg}`} />
            </ListItem>
          ))}
        </List>
      </Alert>
    );
  };

  const today = DateTime.now();

  return (
    <SpotbargeLayout title={t("trading_enter_barge_enter_barge_header")}>
      <Box>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </div>
        )}
        {message.text && (
          <Alert severity={message.severity} sx={{ mb: 2 }}>
            {message.text}
          </Alert>
        )}
        {renderErrors()}
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
          <OutlinedSection title={`1. ${t("trading_enter_barge_trip_basics")}`} sx={{ flex: 1 }}>
            <Box sx={{ p: 1 }}>
              <DatePicker
                label={t("trading_enter_barge_load_eta")}
                value={loadEta}
                onChange={(newValue) => setLoadEta(newValue)}
                minDate={today}
                sx={{ width: '100%' }}
              />
            </Box>
            <Box sx={{ p: 1 }}>
              <Autocomplete
                id="barge-name"
                options={ships}
                value={bargeName}
                onChange={handleBargeNameChange}
                freeSolo
                onInputChange={(_, newInputValue) => {
                  setBargeName(newInputValue);
                  if (newInputValue === "") {
                    setBargeSizeM3("");
                    setBargeSizeDwt("");
                    setBargeEuropeNr("");
                    setTankType("");
                    setHeating("no");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("trading_enter_barge_barge_name")}
                    fullWidth
                  />
                )}
              />
            </Box>
            <Box sx={{ p: 1 }}>
              <TextField
                id="barge-size-m3"
                label={t("trading_enter_barge_barge_size_m3")}
                type="number"
                value={bargeSizeM3}
                onChange={(e) => setBargeSizeM3(e.target.value)}
                fullWidth
              />
            </Box>
            <Box sx={{ p: 1 }}>
              <TextField
                id="barge-size-dwt"
                label={t("trading_enter_barge_barge_size_dwt")}
                type="number"
                value={bargeSizeDwt}
                onChange={(e) => setBargeSizeDwt(e.target.value)}
                fullWidth
              />
            </Box>
            <Box sx={{ p: 1 }}>
              <TextField
                id="barge-europe-nr"
                label={t("trading_enter_barge_barge_europe_nr")}
                type="number"
                value={bargeEuropeNr}
                onChange={(e) => setBargeEuropeNr(e.target.value)}
                fullWidth
              />
            </Box>
            <Box sx={{ p: 1 }}>
              <TextField
                id="tank-type"
                label={t("trading_enter_barge_tank_type")}
                select
                value={tankType}
                onChange={(e) => setTankType(e.target.value)}
                fullWidth
              >
                <MenuItem value="coated">{t("trading_enter_barge_coated")}</MenuItem>
                <MenuItem value="mildsteel">{t("trading_enter_barge_mild_steel")}</MenuItem>
                <MenuItem value="stainless_steel">{t("trading_enter_barge_stainless_steel")}</MenuItem>
              </TextField>
            </Box>
            <Box sx={{ p: 1, ml: 2 }}>
              <Typography component="div" sx={{ mb: 1 }}>{t("trading_enter_barge_heating")}</Typography>
              <RadioGroup
                row
                value={heating}
                onChange={(e) => setHeating(e.target.value)}
              >
                <FormControlLabel value="yes" control={<Radio />} label={t("Yes")} />
                <FormControlLabel value="no" control={<Radio />} label={t("No")} />
              </RadioGroup>
            </Box>
          </OutlinedSection>
          <Box sx={{ flex: 1 }}>
            <OutlinedSection title={`2. ${t("trading_enter_barge_last_3_pre_cargos")}`}>
              <Box sx={{ p: 1 }}>
                <Autocomplete
                  id="pre-cargo-1"
                  options={productOptions}
                  groupBy={(option) => option?.category ?? ""}
                  getOptionLabel={(option) => option?.label ?? ""}
                  value={preCargo1}
                  onChange={(event, newValue) => setPreCargo1(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("trading_enter_barge_pre_cargo_1")}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box sx={{ p: 1 }}>
                <Autocomplete
                  id="pre-cargo-2"
                  options={productOptions}
                  groupBy={(option) => option?.category ?? ""}
                  getOptionLabel={(option) => option?.label ?? ""}
                  value={preCargo2}
                  onChange={(event, newValue) => setPreCargo2(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("trading_enter_barge_pre_cargo_2")}
                      fullWidth
                    />
                  )}
                />
              </Box>
              <Box sx={{ p: 1 }}>
                <Autocomplete
                  id="pre-cargo-3"
                  options={productOptions}
                  groupBy={(option) => option?.category ?? ""}
                  getOptionLabel={(option) => option?.label ?? ""}
                  value={preCargo3}
                  onChange={(event, newValue) => setPreCargo3(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("trading_enter_barge_pre_cargo_3")}
                      fullWidth
                    />
                  )}
                />
              </Box>
            </OutlinedSection>
            <Box sx={{ display: "flex", flexDirection: { xs: 'column', sm: 'row' } }}>
              <OutlinedSection title={`3. ${t("trading_enter_barge_available_for_areas")}`} sx={{ flex: 1 }}>
                <Box sx={{ p: 0.5 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={areas.includes("FARAG-FARAG")}
                        onChange={handleAreaChange("FARAG-FARAG")}
                      />
                    }
                    label="FARAG - FARAG (NL/BE)"
                  />
                </Box>
                <Box sx={{ p: 0.5 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={areas.includes("FARAG-Rhine")}
                        onChange={handleAreaChange("FARAG-Rhine")}
                      />
                    }
                    label="FARAG - Rhine"
                  />
                </Box>
                <Box sx={{ p: 0.5 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={areas.includes("Rhine-FARAG")}
                        onChange={handleAreaChange("Rhine-FARAG")}
                      />
                    }
                    label="Rhine - FARAG"
                  />
                </Box>
                <Box sx={{ p: 0.5 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={areas.includes("Rhine-Rhine")}
                        onChange={handleAreaChange("Rhine-Rhine")}
                      />
                    }
                    label="Rhine - Rhine"
                  />
                </Box>
              </OutlinedSection>
              <OutlinedSection title={`4. ${t("trading_enter_barge_tanks")}`} sx={{ flex: 1 }}>
                <Box sx={{ p: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tanks.includes("gasfree")}
                        onChange={handleTankChange("gasfree")}
                      />
                    }
                    label={t("trading_enter_barge_gasfree")}
                  />
                </Box>
                <Box sx={{ p: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tanks.includes("oxyfree")}
                        onChange={handleTankChange("oxyfree")}
                      />
                    }
                    label={t("trading_enter_barge_oxy_free")}
                  />
                </Box>
                <Box sx={{ p: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={tanks.includes("bio")}
                        onChange={handleTankChange("bio")}
                      />
                    }
                    label={t("trading_enter_barge_bio_free")}
                  />
                </Box>
              </OutlinedSection>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-end' }, flexDirection: { xs: 'column', md: 'row' } }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ width: { sm: '100%', md: 'fit-content' }, minWidth: 150 }}
              >
                {t("trading_enter_barge_submit")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </SpotbargeLayout>
  );
}

export default withErrorBoundary(EnterBarge, {
  fallback: <GeneralErrorPage />,
});
