import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  CircularProgress,
  Alert,
  TextField,
  Button,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { Autocomplete } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import SpotbargeLayout from "../../common/SpotbargeLayout";
import { useTradingGet } from "../../../request";
import OutlinedSection from "../../atoms/OutlinedSection";
import SpotbargeTable from "../../common/SpotbargeTable";
import GeneralErrorPage from "../../GeneralErrorPage";
import { withErrorBoundary } from "react-error-boundary";
import BidDialog from "../BidDialog";

const FindBarge = () => {
  const { t } = useTranslation();
  const [cargos, setCargos] = useState([]);
  const [selectedCargo, setSelectedCargo] = useState(null);
  const [barges, setBarges] = useState([]);
  const [filteredBarges, setFilteredBarges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [flexibilityDays, setFlexibilityDays] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [preCargoPreference, setPreCargoPreference] = useState({
    Ethanol: false,
    Diesel: false,
    Gasoline: false,
    Fame: false,
    Naptha: false,
    JetKero: false,
  });
  const [selectedBarge, setSelectedBarge] = useState(null);

  const get = useTradingGet();

  useEffect(() => {
    const fetchCargos = async () => {
      try {
        const result = await get("your-cargos");
        if (result && !result.error) {
          setCargos(result.filter((cargo) => cargo.status.toLowerCase() === "pending").map((cargo) => ({
            ...cargo,
            loadWindow: `${DateTime.fromFormat(cargo.loadStart, "yyyy-MM-dd").toFormat("dd/MM/yyyy")} - ${DateTime.fromFormat(cargo.loadEnd, "yyyy-MM-dd").toFormat("dd/MM/yyyy")}`,
          })));
        } else {
          setError(result.error ? result.error : t("index_somethingWentWrong"));
        }
      } catch (err) {
        console.log(err);
        setError(t("index_somethingWentWrong"));
      } finally {
        setLoading(false);
      }
    };

    fetchCargos();
  }, []);

  const handleCargoChange = async (event, newValue) => {
    setSelectedCargo(newValue);
    if (newValue) {
      setTableLoading(true);
      try {
        const result = await get("find-barge", { cargoId: newValue.id });
        if (result && !result.error) {
          const formattedBarges = result.map((barge) => ({
            ...barge,
            loadDate: `${DateTime.fromFormat(barge.loadEta, "yyyy-MM-dd").toFormat("dd/MM/yyyy")}`,
          }));
          setBarges(formattedBarges);
          filterBarges(formattedBarges, newValue, flexibilityDays);
        } else {
          setError(result.error ? result.error : t("index_somethingWentWrong"));
        }
      } catch (err) {
        console.log(err);
        setError(t("index_somethingWentWrong"));
      } finally {
        setTableLoading(false);
      }
    }
  };

  const filterBarges = (barges, cargo, flexibilityDays) => {
    const filtered = barges.filter((barge) => {
      const loadEta = DateTime.fromFormat(barge.loadEta, "yyyy-MM-dd");
      const loadStart = DateTime.fromFormat(cargo.loadStart, "yyyy-MM-dd");
      const loadEnd = DateTime.fromFormat(cargo.loadEnd, "yyyy-MM-dd");
      const daysFlexibility = parseInt(flexibilityDays) || 0;

      return (
        (loadEta >= loadStart && loadEta <= loadEnd) ||
        (loadEta >= loadStart.minus({ days: daysFlexibility }) && loadEta <= loadStart)
      );
    });
    setFilteredBarges(filtered);
  };

  useEffect(() => {
    if (selectedCargo) {
      filterBarges(barges, selectedCargo, flexibilityDays);
    }
  }, [flexibilityDays, barges, selectedCargo]);

  const handleBidClick = (barge) => {
    setSelectedBarge(barge);
  };

  const handleBidClose = () => {
    setSelectedBarge(null);
  };

  const columns = useMemo(
    () => [
      { id: "loadDate", label: t("trading_barges_loadDate") },
      { id: "bargeName", label: t("trading_barges_bargeName") },
      { id: "bargeSizeM3", label: t("trading_barges_bargeSizeM3") },
      { id: "bargeSizeDwt", label: t("trading_barges_bargeSizeDwt") },
      { id: "bargeEuropeNr", label: t("trading_barges_bargeEuropeNr") },
      { id: "l3c", label: t("trading_barges_l3c") },
      { id: "areas", label: t("trading_barges_areas") },
      { id: "company", label: t("trading_barges_company") },
      {
        id: "bid",
        label: "",
      },
    ],
    [t]
  );

  const columnRenderMap = {
    bid: (row) => (
      <Button 
        variant="contained" 
        color="success"
        onClick={() => handleBidClick(row)}
      >
        {t("Bid")}
      </Button>
    ),
    l3c: (row) => (
      <span>
        {row.preCargo.map((cargo, index) => (
          <div key={index}>{index + 1}. {cargo}</div>
        ))}
      </span>
    ),
    areas: (row) => (
      <span>{row.areas.join(", ")}</span>
    ),
  };

  const handlePreCargoChange = (event) => {
    setPreCargoPreference({
      ...preCargoPreference,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <SpotbargeLayout title={t("Filter your search")}>
      <Box sx={{ p: 1 }}>
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {!loading && !error && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Box sx={{ flex: "1 1 100%" }}>
              <OutlinedSection title={t("Select from your cargos")}>
                <Autocomplete
                  fullWidth
                  options={cargos}
                  getOptionLabel={(cargo) =>
                    `Cargo trip: ${cargo.loadWindow} / ${cargo.loadTerminal} - ${cargo.dischargeTerminal} / ${cargo.volume}t / ${cargo.product}`
                  }
                  value={selectedCargo}
                  onChange={handleCargoChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Select a cargo")}
                      variant="outlined"
                    />
                  )}
                  renderOption={(props, cargo) => (
                    <li style={{ display: 'flex', flexWrap: 'wrap' }} {...props}>
                      <span style={{ fontWeight: 'bold' }}>Load Window:&nbsp;</span>{cargo.loadWindow},&nbsp;
                      <span style={{ fontWeight: 'bold' }}>Cargo trip:&nbsp;</span>{cargo.loadTerminal} - {cargo.dischargeTerminal},&nbsp;
                      <span style={{ fontWeight: 'bold' }}>Volume:&nbsp;</span>{cargo.volume}t,&nbsp;
                      <span style={{ fontWeight: 'bold' }}>Product:&nbsp;</span>{cargo.product},&nbsp;
                    </li>
                  )}
                />
              </OutlinedSection>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 1,
              }}
            >
              <Box sx={{ flex: "1 1 50%" }}>
                <OutlinedSection title={t("Flexibility dates")}>
                  <RadioGroup
                    row
                    value={flexibilityDays}
                    onChange={(e) => setFlexibilityDays(e.target.value)}
                  >
                    <FormControlLabel value="1" control={<Radio />} label="< 1 day" />
                    <FormControlLabel value="2" control={<Radio />} label="< 2 days" />
                    <FormControlLabel value="3" control={<Radio />} label="< 3 days" />
                  </RadioGroup>
                </OutlinedSection>
              </Box>
              <Box sx={{ flex: "1 1 50%" }}>
                <OutlinedSection title={t("Pre cargo preference")}>
                  <FormGroup row>
                    {Object.keys(preCargoPreference).map((key) => (
                      <FormControlLabel
                        key={key}
                        control={
                          <Checkbox
                            checked={preCargoPreference[key]}
                            onChange={handlePreCargoChange}
                            name={key}
                          />
                        }
                        label={key}
                      />
                    ))}
                  </FormGroup>
                </OutlinedSection>
              </Box>
            </Box>
            {selectedCargo && !error && (
              <Box sx={{ position: "relative" }}>
                {tableLoading && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      zIndex: 1,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
                <SpotbargeTable data={filteredBarges} columns={columns} columnRenderMap={columnRenderMap} />
              </Box>
            )}
          </Box>
        )}

        <BidDialog open={!!selectedBarge} onClose={handleBidClose} />
      </Box>
    </SpotbargeLayout>
  );
};

export default withErrorBoundary(FindBarge, {
  fallback: <GeneralErrorPage />,
});